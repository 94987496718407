const HomeServiceSelectBranch = () => import('../pages/home/homeService/Cabang.vue')
const Index = () => import('../pages/home/homeService/Index.vue')
const Checkout = () => import('../pages/home/homeService/Checkout.vue')

export default [
  {
    path: '/home-service/pilih-cabang',
    name: 'HomeServiceSelectBranch',
    component: HomeServiceSelectBranch,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home-service/pilih-layanan',
    name: 'HomeServiceIndex',
    component: Index,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/home-service/checkout',
    name: 'HomeServiceCheckout',
    component: Checkout,
    meta: {
      requiresAuth: true
    }
  }
]
