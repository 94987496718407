import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'
import { reactive, ref } from 'vue'
import axios from '../plugins/axios'
import { user } from './auth'
import { getNotifications, hideAskForRequest } from './notifications'

export const fcmToken = ref(
  localStorage.getItem('firebase_token')
)

export const loading = reactive({
  getToken: false
})

const firebaseConfig = {
  apiKey: 'AIzaSyCD6mdar_VOAoFJ7jzZ4ugDhz5XYdGtwsA',
  authDomain: 'parahita-3c288.firebaseapp.com',
  projectId: 'parahita-3c288',
  storageBucket: 'parahita-3c288.appspot.com',
  messagingSenderId: '550246364710',
  appId: '1:550246364710:web:546b870ba9e8afd512d8e7',
  measurementId: 'G-9DWX864QQM'
}

firebase.initializeApp(firebaseConfig)

export const analytics = firebase.analytics()

// export aja biar bisa dipanggil di file lain
export const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null

// INI DIPANGGILNYA JANGAN PAS PERTAMA KALI BUKA
export const getFirebaseToken = async () => {
  if (!firebase.messaging.isSupported()) {
    console.log('firebase is not supported')
    return
  }

  if (!user.value) {
    console.log('no user?')
    return
  }

  try {
    loading.getToken = true
    const token = await messaging.getToken()

    // buat testing tak taruh sini dulu
    localStorage.setItem('firebase_token', token)
    fcmToken.value = token

    await axios.post('app/token_frbs', {
      email: user.value.email_user,
      token_login: token
    })

    analytics.logEvent('notification_permitted')

    hideAskForRequest.value = true

    return token
  } catch (error) {
    console.error(error)
    console.error('user tidak mengijinkan')
    throw error
  } finally {
    loading.getToken = false
  }
}

/**
 * Langsung get token firebase kalau udah diijinkan
 * tapi token belum diset, misal karena habis logout
 * terus login lagi
 */
export const getTokenAfterLogin = () => {
  if (!localStorage.getItem('firebase_token') &&
    'Notification' in window &&
    Notification.permission === 'granted') {
    getFirebaseToken()
  }
}

if (firebase.messaging.isSupported()) {
  messaging.onMessage((payload) => {
    console.log('new message!')
    console.log(payload)
    if ('Notification' in window && Notification.permission === 'granted') {
      const notif = new Notification(payload.data.title, {
        body: payload.data.body,
        icon: '/img/icons/playstore.png'
      })

      notif.onclick(() => {
        window.location = '/profile/notifications'
      })
    }

    getNotifications()
  })
}
