import { reactive, ref, watch } from 'vue'
import axios from '../plugins/axios'
import { analytics } from './firebase-messaging'

export const subDivisions = ref([])
export const selectedPromo = ref(null)
export const selectedBranch = ref(
  JSON.parse(localStorage.getItem('selectedBranch')) || null
)

watch(selectedBranch, () => {
  localStorage.setItem('selectedBranch', JSON.stringify(selectedBranch.value))
  analytics.logEvent('select_branch', selectedBranch.value)
})

export const loading = reactive({
  subDivisions: false
})

/**
 * Contoh return. Tapi kalau misal sub divisions, datanya dipakai di lebih dari satu komponen,
 * bisa pakai kayak contoh loadServices aja.
 */
export const getSubDivisions = async () => {
  const KEY = 'branches'
  subDivisions.value = JSON.parse(
    localStorage.getItem(KEY)
  ) || []

  try {
    loading.subDivisions = true

    const { data } = await axios.get('app/cabang')

    subDivisions.value = data.data
    localStorage.setItem(KEY, JSON.stringify(data.data))
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.subDivisions = false
  }
}
