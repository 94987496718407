import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import axios from '../plugins/axios'
import { user } from './auth'
import { analytics, fcmToken, messaging } from './firebase-messaging'
import { ORDER_COUNT } from './profile'

export const notifications = ref([])
export const loading = {
  notification: false,
  notificationDetail: false
}

const KEY_HIDE_REQUEST_COUNT = 'HIDE_ASK_FOR_PERMISSON_COUNT'
export const hideAskForRequest = ref(
  parseInt(localStorage.getItem(KEY_HIDE_REQUEST_COUNT) || 0) > 1
)

watch(hideAskForRequest, () => {
  localStorage.setItem(
    KEY_HIDE_REQUEST_COUNT,
    parseInt(localStorage.getItem(KEY_HIDE_REQUEST_COUNT) || 0) + 1
  )

  analytics.logEvent('hide_notification_prompt')
})

/**
 * logika kapan harus minta permission notif
 */
export const shouldAskNotifPermission = computed(() => {
  const supported = 'Notification' in window && messaging
  const notBlockedBefore = supported && Notification?.permission === 'default'
  const notGeneratedBefore = fcmToken.value === null
  const hasOrderedSomething = ORDER_COUNT.value > 0
  const route = useRoute()

  const shouldAsk = (
    (
      notifications.value.length ||
      hasOrderedSomething
    ) &&
    ['Notifications', 'Home'].includes(route?.name)
  )

  const result = ((supported &&
    notBlockedBefore &&
    notGeneratedBefore &&
    shouldAsk &&
    !hideAskForRequest.value) ||
    route?.query.debug)

  /**
   * I love tracking
   */
  if (result) {
    analytics.logEvent('prompt_notification')
  }

  return result
})

export const getNotifications = async () => {
  const KEY = 'notifications'

  try {
    loading.notification = true
    // load from localStorage
    notifications.value = JSON.parse(localStorage.getItem(KEY)) ?? []

    const { data } = await axios.get('app/list_notifikasi', {
      params: {
        id: user.value.id
      }
    })

    notifications.value = data.data
    localStorage.setItem(KEY, JSON.stringify(data.data))
  } catch (error) {
    console.error('Error dari profile get notif', error)
    throw error
  } finally {
    loading.notification = false
  }
}

export const getNotificationDetails = async (payload) => {
  try {
    loading.notificationDetail = true

    const { data } = await axios.get('app/list_notifikasi_single', {
      params: payload
    })

    if (data.success) {
      return data.data[0]
    }

    throw data
  } catch (error) {
    console.error('Error dari profile get notif detail = ', error)
    throw error
  } finally {
    loading.notificationDetail = false
  }
}

export const notificationCount = computed(() => {
  if (notifications.value && notifications.value.length) {
    const length = notifications.value.filter((notification) => {
      return parseInt(notification.status_read) === 0
    }).length

    // display badge kalau support :V
    if (length && 'setAppBadge' in navigator) {
      navigator
        .setAppBadge(length)
        .then(() => {
          analytics.logEvent('set_web_app_badge', { length })
        })
    }

    return length
  }

  return 0
})
