import { ref } from 'vue'
import { analytics } from './firebase-messaging'

export const timeToInstall = ref(false)
export const promptInstalasiNative = ref(null)

/**
 * Strategi untuk instalasi PWA (parahita lite version)
 */
window.addEventListener('beforeinstallprompt', ev => {
  ev.preventDefault()

  promptInstalasiNative.value = ev
  timeToInstall.value = true

  analytics.logEvent('prompt_instalasi_pwa')

  console.log('ok, prompt instalasi sudah ditangkap')
})

window.addEventListener('appinstalled', () => {
  console.log('web app was installed')
  analytics.logEvent('web_app_installed')
})
