import { reactive, ref } from 'vue'
import axios from '../plugins/axios'
import { user } from './auth'
import { ORDER_COUNT } from './profile'
import { cart, clearCart } from './cart'
import { analytics } from './firebase-messaging'
import { selectedBranch } from './subDivisions'

/**
 * services digunakan untuk menyimpan
 * list services dari API, pakai ref compose API vue js 3
 */
export const services = ref([])

/**
 * Ambil old services dari localeStorage sehingga working on offline
 */
services.value = JSON.parse(localStorage.getItem('main.services')) || []

export const vouchers = ref([])
export const detailServices = ref([])
export const detailService = ref([])
export const activeService = ref(null)
export const selectedMember = reactive({
  nama: '',
  pas_id_periksa: null,
  email: ''
})

/**
 * konstanta digunakan untuk mentracking semua loading ajax yang berhubungan dengan service.
 */
export const loading = reactive({
  services: false,
  detailServices: false,
  detailService: false,
  order: false,
  vouchers: false
})

export const loadServices = async () => {
  try {
    loading.services = true
    const { data } = await axios.get('app/layanan')

    // untuk ref(), assign-nya harus pakai .value kalau di luar vue-template
    services.value = data.data

    // save ke localeStorage
    localStorage.setItem('main.services', JSON.stringify(data.data))
  } catch (error) {
    console.error(error) // biar gak kena linting

    // ini terserah mau dihandle di sini atau di tempat waktu manggil
    // fungsi
    throw error
  } finally {
    // jadi kalau ajax-nya gagal, loading-nya tetep dianggap selesai
    loading.services = false
  }
}

export const getServiceDetails = async (payload) => {
  const KEY = `services.${payload.idGroup}.cabang.${payload.cabang}.sub`

  // fallback localStorage
  detailServices.value = JSON.parse(
    localStorage.getItem(KEY)
  ) || []

  try {
    loading.detailServices = true
    const { data } = await axios.get('app/layanan_detail', {
      params: {
        cabang: payload.cabang,
        id_group: payload.idGroup
      }
    })

    detailServices.value = data.data
    localStorage.setItem(KEY, JSON.stringify(data.data))
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.detailServices = false
  }
}

export const getServiceDetail = async (payload) => {
  try {
    loading.detailService = true
    const { data } = await axios.get('app/layanan_detail_single', {
      params: {
        cabang: payload.cabang,
        id_dt: payload.idDt
      }
    })

    detailService.value = data.data[0]
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.detailService = false
  }
}

export const getSubServiceDetail = async (payload) => {
  try {
    loading.detailService = true
    const { data } = await axios.get('app/layanan_detail_single', {
      params: {
        cabang: payload.cabang,
        id_dt: payload.idDt
      }
    })

    return data.data[0]
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.detailService = false
  }
}

export const submitOrder = async () => {
  try {
    loading.order = true

    // Isi data yang kurang
    cart.value.pas_id_pendaftar = user.value.pas_id
    cart.value.id_pendaftar = user.value.id

    if (cart.value.status_periksa === 1) {
      cart.value.pas_id_periksa = user.value.pas_id
    } else {
      cart.value.pas_id_periksa = selectedMember.pas_id_periksa
    }

    // Format tgl_periksa
    const tglPeriksa = new Date(cart.value.tgl_periksa)
    cart.value.tgl_periksa = tglPeriksa.getFullYear() + ('0' + (tglPeriksa.getMonth() + 1)).slice(-2) + ('0' + tglPeriksa.getDate()).slice(-2)

    const { data } = await axios.post('app/daftar', cart.value)

    const trackingObject = {
      items: cart.value.arr_paket.map(_item => ({
        item_id: _item.kode,
        item_name: _item.nama,
        price: parseFloat(_item.harga),
        currency: 'IDR',
        item_category: _item.group,
        item_list_name: `${_item.group} cabang ${selectedBranch.value?.nama}`,
        item_list_id: `${_item.id_group}_cabang_${selectedBranch.value?.id}`,
        quantity: 1
      })),
      value: parseFloat(cart.value.ap_nombayar),
      currency: 'IDR',
      transaction_id: data.data.kode_daftar
    }

    analytics.logEvent('purchase', trackingObject)

    // Clear cart dulu baru return
    await clearCart(false) // tanpa notif sukses
    ORDER_COUNT.value++

    return data
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    loading.order = false
  }
}

export const getConfirmation = async () => {
  try {
    const { data } = await axios.get('app/getsetting', {
      params: {
        setting: 'konfirmasi_daftar'
      }
    })
    if (data.success === true) {
      return {
        status: true,
        message: data.data
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error(error)

    return {
      status: false,
      message: error.response.data?.message ?? 'Error konfirmasi'
    }
  }
}

export const getVouchers = async () => {
  try {
    loading.vouchers = true
    const { data } = await axios.get('app/list_voucher')

    if (data.success) {
      vouchers.value = data.data
      return
    }

    console.error('Error dari service get vouchers = ', data.message)
    // Reset datanya kalau error
    vouchers.value = []
    throw data
  } catch (error) {
    console.error('Error dari service get vouchers = ', error)
    vouchers.value = []
  } finally {
    loading.vouchers = false
  }
}
