<template>
  <div
    id="app"
    :class="{'pb-5 pb-md-0 mb-4 mb-md-0': withMobileMainBottomBar}">
    <second-navbar
      v-if="$route.meta.usingSecondNavbar"
      class="d-none d-md-block"/>
    <navbar
      v-else-if="!$route.meta.noNavbar"
      class="d-none d-md-block" />
    <router-view />
    <modal-service />
    <main-bottom-bar
      v-if="withMobileMainBottomBar"
    />
    <div class="toast-container position-fixed p-3 top-50 start-50 translate-middle">
      <Toast/>
    </div>
  </div>
</template>

<script>
import Toast from './components/utility/Toast'
import MainBottomBar from './components/home/MainBottomBar.vue'
import { withMobileMainBottomBar, movingBack } from '@/core/page'
import { getTokenAfterLogin } from './core/firebase-messaging'
import ModalService from './components/ModalService.vue'
import { onMounted } from 'vue'
import { Modal } from 'bootstrap'
import { useRoute, useRouter } from 'vue-router'
import Navbar from './components/Navbar.vue'
import SecondNavbar from './components/SecondNavbar.vue'

let mainServiceModal = null
let modalElement = null

export default {
  components: { MainBottomBar, Toast, ModalService, Navbar, SecondNavbar },
  setup () {
    getTokenAfterLogin()
    const router = useRouter()
    const route = useRoute()

    onMounted(async () => {
      modalElement = document.querySelector('#modal-main-service')

      modalElement.addEventListener('hidden.bs.modal', () => {
        if (route.query.showMainServiceModal === 'true') {
          router.replace({
            ...route.query,
            showMainServiceModal: null
          })
        }
      })
    })

    return {
      withMobileMainBottomBar,
      movingBack
    }
  },
  watch: {
    '$route.query' (query) {
      this.$nextTick(() => {
        if (query.showMainServiceModal === 'true') {
          mainServiceModal = new Modal(modalElement)
          mainServiceModal.show()
        } else {
          if (mainServiceModal) {
            setTimeout(() => {
              mainServiceModal.hide()
              this.$nextTick(() => {
                mainServiceModal = null
              })
            }, 300)
          }
        }
      })
    }
  }
}
</script>

<style lang="scss">
@import '~remixicon/fonts/remixicon.css';

#main {
  overflow-x: hidden;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.fade-enter-active, .fade-leave-active {
  transition: all 0.3s ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

</style>
