import { selectedBranch } from '../core/subDivisions'

const Fpp = () => import('../pages/home/service/booking/Fpp.vue')
const FppDetail = () => import('../pages/home/service/booking/FppDetail.vue')

export default [
  {
    path: '/fpp/:id?',
    name: 'Fpp',
    component: Fpp,
    beforeEnter: (to, from, next) => {
      if (!selectedBranch.value) {
        return next({
          name: 'SelectBranch',
          query: {
            redirectTo: '/fpp'
          }
        })
      }
      next()
    }
  },
  {
    path: '/fpp/:id/:kode',
    name: 'FppDetail',
    component: FppDetail,
    beforeEnter: (to, from, next) => {
      if (!selectedBranch.value) {
        return next({
          name: 'SelectBranch',
          query: {
            redirectTo: '/fpp'
          }
        })
      }
      next()
    }
  }
]
