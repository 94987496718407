import axios from 'axios'
import { token } from '../core/auth'

const client = axios.create({
  baseURL: 'https://lab.aksamedia.co.id/api/', // nanti pakai .env aja
  // baseURL: 'https://its.labparahita.com/api/',
  // baseURL: 'https://api.parahita.aksamedia.co.id/',
  auth: {
    username: 'pedece',
    password: '@@parahitax#'
  },
  withCredentials: true
})

/**
 * Kasih parameter token_api untuk semua
 * request axios jika posisi sedang login
 */
client.interceptors.request.use(config => {
  if (token && token.value) {
    if (config.method === 'get') {
      if (!config.params) {
        config.params = {}
      }

      config.params.token_api = token.value
    } else {
      if (!config.data) {
        config.data = {}
      }

      config.data.token_api = token.value
    }
  }

  // console.log(config)

  return config
})

export default client
