<template>
  <nav
    class="d-flex d-md-none position-fixed bg-white justify-content-evenly align-items-center">
    <router-link
      to="/"
      class="menu-item"
      :class="{'router-link-exact-active':$route.name==='MCU'}"
    >
      <img v-if="$route.name === 'Home' || $route.name === 'MCU'" src="@/assets/icon/home.svg" alt="Home">
      <img v-else src="@/assets/icon/home-outline.svg" alt="Home">
      <span class="fs-10" :class="[$route.name === 'Home' || $route.name === 'MCU' ? 'color-primary-500 fw-600':'color-neutral-600 fw-400']">Home</span>
    </router-link>
    <router-link
      :to="{ name: 'ProfilOrder' }"
      class="menu-item"
    >
      <img v-if="$route.name === 'ProfilOrder'" src="@/assets/icon/pesanan.svg" alt="Home">
      <img v-else src="@/assets/icon/pesanan-outline.svg" alt="Home">
      <span class="fs-10" :class="[$route.name === 'ProfilOrder' ? 'color-primary-500 fw-600':'color-neutral-600 fw-400']">Pesanan</span>
    </router-link>

    <router-link
      :to="{ name: 'Hasil' }"
      class="menu-item"
    >
      <img v-if="['Hasil', 'Monitoring', 'MonitoringMcu'].includes($route.name)" src="@/assets/icon/lab.svg" alt="Home">
      <img v-else src="@/assets/icon/lab-outline.svg" alt="Home">
      <span class="fs-10" :class="[['Hasil', 'Monitoring', 'MonitoringMcu'].includes($route.name) ? 'color-primary-500 fw-600':'color-neutral-600 fw-400']">Hasil Lab</span>
    </router-link>

    <router-link
      :to="{ name: 'Kontak' }"
      class="menu-item"
    >
      <img v-if="$route.name === 'Kontak'" src="@/assets/icon/kontak.svg" alt="Home">
      <img v-else src="@/assets/icon/kontak-outline.svg" alt="Home">
      <span class="fs-10" :class="[$route.name === 'Kontak' ? 'color-primary-500 fw-600':'color-neutral-600 fw-400']">Kontak</span>
    </router-link>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  z-index: 999;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  box-shadow: 0px -1px 1rem #00000023;

  .menu-item {
    display: flex;
    flex-direction: column;
    padding: .6rem 1rem;
    font-size: .8rem;
    color: #BDBDBD;
    text-align: center;
    align-items: center !important;

    img {
      width: 24px;
      height: 24px;
      margin-bottom: .25rem;
    }
  }
}
</style>
