import Jimp from 'jimp'
import { reactive } from 'vue'

export const notif = reactive({
  show: false,
  status: 'success', // success, danger, warning
  message: '' // Pesan yang ditampilkan
})

export const showNotif = (payload) => {
  notif.show = true
  notif.status = payload.status
  notif.message = payload.message
}

export const resetNotif = () => {
  notif.show = false
  notif.status = 'success'
  notif.message = ''
}

export const urlToFile = async (url, name) => {
  const response = await fetch(url)
  // here image is url/location of url
  const blob = await response.blob()
  const file = await new File([blob], name || url, {
    type: blob.type
  })

  return file
}

export const compressImage = async (file, width) => {
  const image = await Jimp.read(URL.createObjectURL(file))
  image.resize(width, Jimp.AUTO)
  const imageBase64 = await image.getBase64Async(image.getMIME())

  return imageBase64
}

export const DataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new Blob([ia], { type: mimeString })
}

export const dataURLtoBlob = (dataURL) => {
  let array = {}
  let binary = {}
  let len = {}
  let i
  // let array, binary, i, len
  binary = atob(dataURL.split(',')[1])
  array = []
  i = 0
  len = binary.length
  while (i < len) {
    array.push(binary.charCodeAt(i))
    i++
  }
  return new Blob([new Uint8Array(array)], {
    type: 'image/png'
  })
}

export const limitText = (str, limit) => {
  // trim the string to the maximum length
  const trimmedString = str.substr(0, limit)

  // re-trim if we are in the middle of a word
  return trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(' ')))
}
