const Index = () => import('@/pages/profile/notifications/Index')
// const Detail = () => import('@/pages/profile/settings/Personal')

export default [
  {
    path: 'notifications',
    name: 'Notifications',
    component: Index,
    meta: {
      noProfileCard: true, // for mobile
      overrideAppBarTitle: 'Pemberitahuan'
    }
  }
  // {
  //   path: 'setting/personal-data',
  //   name: 'SettingPersonalData',
  //   component: Detail,
  //   meta: {
  //     noProfileCard: true
  //   }
  // }
]
