import './core/auth'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueSignature from 'vue-signature-pad'

// Tak comment kok malah kenek dropdown e ???, aneh
// import 'bootstrap/dist/js/bootstrap' // Import bootstrap nya pakai ini aja

import '@/assets/scss/app.scss'

import '@/static/css/home/custom.css'
import '@/plugins/vee-validate.js'
import './core/installation'
const { analytics, messaging } = () => import('./core/firebase-messaging')

window.addEventListener('error', error => {
  analytics.logEvent('web_error', {
    message: error.message,
    stack: error.error.stack
  })
})

createApp(App)
  .use(router)
  .use(VueSignature)
  .use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyDrtSOyVbHe3KV63OINsJbNgvmD9F4N5kU', // AIzaSyBo2VkN5KlRqhEJEASX3RtpQM_lSDUNIkw
      libraries: 'places'
    }
  })
  .mount('#app')

window.addEventListener('load', () => {
  if ('serviceWorker' in navigator) {
    if (messaging) {
      navigator.serviceWorker.register('/service-worker.js')
    } else {
      console.log('not supported for fcm')
      navigator.serviceWorker
        .getRegistrations()
        .then(registrations => {
          console.log('serviceWorker = ', registrations)
          for (const registration of registrations) {
            registration.unregister()
          }
        })
    }
  }

  /* masang fb event, disable eslint */
  /* eslint-disable */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '472024864027352');
  fbq('track', 'PageView');
  /* eslint-enable */

  window.getSnap = async () => {
    return new Promise((resolve) => {
      if (!window.snap) {
        const midtransDOM = document.createElement('script')
        midtransDOM.src = 'https://app.midtrans.com/snap/snap.js'
        midtransDOM.type = 'text/javascript'
        midtransDOM.defer = true
        midtransDOM.async = true
        midtransDOM.setAttribute('data-client-key', 'SB-Mid-client-GyKioiX9TC0tzhzU')
        midtransDOM.onload = () => {
          console.log('midtrans loaded')
          setTimeout(() => {
            resolve(window.snap)
          }, 300)
        }

        console.log('load midtrans')
        document.body.appendChild(midtransDOM)
      } else {
        resolve(window.snap)
      }
    })
  }
})
