import { createRouter, createWebHistory } from 'vue-router'
import { withMobileMainBottomBar, overridedPrevHistories, movingBack } from '@/core/page'
import { analytics } from '../core/firebase-messaging'
import auth from './auth'
import profile from './profile/index'
import service from './service'
import fpp from './fpp'
import homeService from './homeService'
import mcu from './mcu'
import wallet from './wallet'
import { Modal } from 'bootstrap'
import { getSubDivisions, selectedBranch, subDivisions } from '../core/subDivisions'
const Home = () => import('../pages/home/Index.vue')
const PrivacyPolicy = () => import('../pages/home/PrivacyPolicy.vue')
const Konsultasi = () => import('../pages/home/Konsultasi.vue')
const Kontak = () => import('../pages/home/Kontak.vue')
const Keranjang = () => import('../pages/home/Keranjang.vue')
const Hasil = () => import('../pages/home/Hasil.vue')
const Monitoring = () => import('../pages/home/hasil/Monitoring.vue')
const WebViewMonitoring = () => import('../pages/home/hasil/WebViewMonitoring.vue')
const MonitoringMcu = () => import('../pages/home/hasil/MonitoringMcu.vue')
const AndroidMonitoring = () => import('../pages/home/hasil/AndroidMonitoring.vue')
const AndroidMcuMonitoring = () => import('../pages/home/mcu/AndroidMonitoring.vue')
const Promo = () => import('../pages/home/Promo.vue')
const FlashSale = () => import('../pages/home/flashSale/Index.vue')
const DetailHasil = () => import('../pages/home/hasil/Detail.vue')
const Membership = () => import('../pages/home/membership/Membership.vue')
const DetailReward = () => import('../pages/home/membership/DetailReward.vue')

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { mobileMainBottomBar: true }
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/hasil',
    name: 'Hasil',
    component: Hasil,
    meta: {
      mobileMainBottomBar: true,
      requiresAuth: true
    }
  },
  {
    path: '/monitoring',
    name: 'Monitoring',
    component: Monitoring,
    meta: {
      mobileMainBottomBar: true,
      requiresAuth: true
    }
  },
  {
    path: '/monitoring-mcu',
    name: 'MonitoringMcu',
    component: MonitoringMcu,
    meta: {
      mobileMainBottomBar: true,
      requiresAuth: true
    }
  },
  {
    path: '/hasil/:id_cabang/:no_lab',
    name: 'DetailHasil',
    component: DetailHasil,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/konsultasi',
    name: 'Konsultasi',
    component: Konsultasi,
    meta: {
      mobileMainBottomBar: true
    }
  },
  {
    path: '/kontak',
    name: 'Kontak',
    component: Kontak,
    meta: {
      mobileMainBottomBar: true
    }
  },
  {
    path: '/keranjang',
    name: 'Keranjang',
    component: Keranjang
  },
  {
    path: '/promo',
    name: 'Promo',
    component: Promo,
    meta: {
      mobileMainBottomBar: true
    }
  },
  {
    path: '/flash-sale',
    name: 'FlashSale',
    component: FlashSale,
    meta: {
      mobileMainBottomBar: true
    }
  },
  {
    path: '/android/monitoring',
    name: 'AndroidMonitoring',
    component: AndroidMonitoring,
    meta: {
      mobileMainBottomBar: false,
      requiresAuth: false,
      noNavbar: true
    }
  },
  {
    path: '/android/mcu/monitoring',
    name: 'AndroidMcuMonitoring',
    component: AndroidMcuMonitoring,
    meta: {
      mobileMainBottomBar: false,
      requiresAuth: false,
      noNavbar: true
    }
  },
  {
    path: '/membership',
    name: 'Membership',
    component: Membership,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/membership/:idMemberDt',
    name: 'DetailReward',
    component: DetailReward,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/webview/monitoring',
    name: 'WebViewMonitoring',
    component: WebViewMonitoring,
    meta: {
      mobileMainBottomBar: false,
      requiresAuth: false,
      noNavbar: true
    }
  }
]
  .concat(auth)
  .concat(service)
  .concat(fpp)
  .concat(profile)
  .concat(homeService)
  .concat(mcu)
  .concat(wallet)

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

const recordedHistories = []

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const auth = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))

  /**
   * Langsung pilih branch pertama kalau dapat kunjungan
   * dari gugel bot
   */
  const ua = navigator.userAgent || ''
  const isGooglebot = ua.indexOf('Googlebot') > -1
  const isGooglePageSpeedBot = ua.indexOf('Google Page Speed Insights') > -1 ||
    ua.indexOf('Chrome-Lighthouse') > -1 ||
    ua.indexOf('Lighthouse') > -1
  const isGoogleStoreBot = ua.indexOf('Storebot-Google') > -1

  if ((isGooglebot || isGooglePageSpeedBot || isGoogleStoreBot) && !selectedBranch.value) {
    await getSubDivisions()
    selectedBranch.value = subDivisions.value[0]
  }

  if (to.fullPath === recordedHistories[recordedHistories.length - 2]) {
    movingBack.value = true
    recordedHistories.pop()

    // close modal after click browser back
    const modal = new Modal(
      document.querySelector('.modal')
    )
    modal.hide()

    // remove backdrop
    const backdrop = document.querySelector('.modal-backdrop')
    if (backdrop) {
      backdrop.remove()
    }

    // remove attribute on body
    const bodyStyle = document.querySelector('body')
    if (bodyStyle) {
      bodyStyle.classList.remove('modal-open')
      bodyStyle.removeAttribute('style')
    }

    if (overridedPrevHistories.length) {
      return next({
        ...overridedPrevHistories.pop(),
        replace: true
      })
    }
  } else {
    movingBack.value = false
    recordedHistories.push(to.fullPath)
  }

  overridedPrevHistories.pop()

  // tampilkan main bottom bar di mobile sesuai meta
  withMobileMainBottomBar.value = !!to.meta.mobileMainBottomBar

  if (requiresAuth) {
    if (!auth) {
      analytics.logEvent('need_to_login_first')
      next({ name: 'Login' })
    } else {
      next()
    }
  } else if (auth && to.name === 'Login') {
    // Kalau sudah login jgn masuk halaman login lagi
    next({ name: 'Home' })
  } else if (auth && user.status_text === 'Belum Registrasi') {
    // Jika belum registrasi akan di redirect ke halaman biodata
    next({ name: 'SettingPersonalData' })
  } else {
    next()
  }
})

export default router
