import { computed, reactive, ref, watch } from 'vue'
import axios from '../plugins/axios'
import { analytics, fcmToken, getTokenAfterLogin } from './firebase-messaging'
import { clearCart } from './cart'

export const members = ref([])
export const kode_promo = ref(
  JSON.parse(localStorage.getItem('kodePromo')) || ''
)

watch(kode_promo, () => {
  localStorage.setItem('kodePromo', JSON.stringify(kode_promo.value))
})

export const loading = reactive({
  login: false,
  register: false,
  members: false,
  otp: false,
  checkOtp: false,
  changePassword: false,
  forgotPassword: false
})

// locale storage nggak reactive.
// Kita perlu component sendiri yang reactive
// jadi locale storage cuman buat inisiasi data waktu script pertama kali dieksekusi.
// sisanya, aplikasi bergantung pada variabel reactive vuejs
export const user = ref(null)
export const token = ref(localStorage.getItem('token') || null)

if (localStorage.getItem('token')) {
  user.value = JSON.parse(localStorage.getItem('user'))

  try {
    analytics.setUserId(
      user.value.id
    )
  } catch (error) {
    console.error(error)
  }
}

export const isLoggedIn = computed(() => {
  return !!token?.value
})

export const dataRegister = reactive({
  nama: null,
  email: null,
  password: null,
  noktp: null,
  tgllahir: null,
  jenkelamin: 'L',
  alamat: null,
  kota: null,
  nowa: null,
  no_hp: null
})

export const register = async (payload) => {
  try {
    loading.register = true
    const { data } = await axios.post('app/registrasi', payload)

    if (data.success === true) {
      analytics.logEvent('sign_up')
      return {
        status: true,
        message: 'Sukses'
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth register', error)
    throw error
  } finally {
    loading.register = false
  }
}

export const sendOtp = async (payload) => {
  try {
    loading.otp = true
    const { data } = await axios.post('app/kirimotp', payload)

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth send otp = ', error)
    throw error
  } finally {
    loading.otp = false
  }
}

export const login = async ({ email, password, token_login }) => {
  try {
    loading.login = true
    const { data } = await axios.post('app/login', {
      email, password, token_login
    })

    if (data.success === true) {
      user.value = data.data[0]
      token.value = data.data[0].token_api

      // save ke locale storage agar nanti kalau situsnya direfresh, data bisa
      // persistent, tapi setelah itu localeStorage-nya nggak dipakai lagi
      localStorage.setItem('user', JSON.stringify(user.value))
      localStorage.setItem('token', token.value)
      getTokenAfterLogin()

      analytics.logEvent('login')

      try {
        analytics.setUserId(
          user.value.id
        )
      } catch (error) {
        console.error(error)
      }

      return {
        status: true,
        message: 'Login Sukses'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth login', error)
    throw error
  } finally {
    loading.login = false
  }
}

export const loginByPhone = async ({ phone }) => {
  try {
    loading.login = true
    const { data } = await axios.post('app/login_v2', {
      no_hp: phone,
      device: 'WEB'
    })

    if (data.success === true) {
      return {
        status: true,
        message: data.message
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth login V2', error)
    throw error
  } finally {
    loading.login = false
  }
}

export const loginOtpByPhone = async ({ no_hp, kode_otp, token_login }) => {
  try {
    loading.otp = true
    const { data } = await axios.post('app/otp_v2', {
      no_hp,
      device: 'WEB',
      kode_otp,
      token_login
    })

    if (data.success === true) {
      user.value = {
        ...data.data[0],
        status_text: data.status_text
      }
      token.value = data.data[0].token_api

      localStorage.setItem('user', JSON.stringify(user.value))
      localStorage.setItem('token', token.value)
      getTokenAfterLogin()

      return {
        status: true,
        message: 'Login Sukses'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari login OTP V2', error)
    throw error
  } finally {
    loading.otp = false
  }
}

export const registerNewMember = async (payload) => {
  try {
    loading.register = true
    const { data } = await axios.post('app/registrasi_anggota', payload)

    if (data.success === true) {
      getTokenAfterLogin()

      return {
        status: true,
        message: 'Sukses'
      }
    }

    analytics.logEvent('add_anggota')

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth register new member = ', error)
    throw error
  } finally {
    loading.register = false
  }
}

export const getMembers = async (payload) => {
  try {
    loading.members = true

    const { data } = await axios.get('app/list_anggota', {
      params: payload
    })

    if (data.success === true) {
      members.value = data.data
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth get member = ', error)
  } finally {
    loading.members = false
  }
}

export const showPassword = (inputType) => {
  if (inputType === 'password') {
    return 'text'
  } else {
    return 'password'
  }
}

export const changePassword = async (payload) => {
  try {
    loading.changePassword = true
    const { data } = await axios.post('app/ganti_password', payload)

    if (data.success === true) {
      analytics.logEvent('change_password')
      return {
        status: true,
        message: 'Sukses'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari auth change passowrd = ', error)
    throw error
  } finally {
    loading.changePassword = false
  }
}

export const logout = async () => {
  try {
    analytics.logEvent('logout')

    // Remove all cart juga ya
    await clearCart(false) // tanpa notif sukses

    const { data } = await axios.post('app/logout', {
      email: user.value.email_user,
      token_login: fcmToken.value
    })

    if (data.success) {
      const whitelist = [
        'main.services',
        'branches',
        'main.fpps',
        'fppDetails.',
        'services.',
        'main.sliders'
      ]

      const localStorageValues = {}
      const localKeys = Object.keys(localStorage)

      for (const key of whitelist) {
        localKeys.forEach(_key => {
          if (_key.search(key) > -1) {
            localStorageValues[_key] = localStorage.getItem(_key)
          }
        })
      }

      localStorage.clear()

      for (const key in localStorageValues) {
        localStorage.setItem(key, localStorageValues[key])
      }

      window.location.reload()
      return
    }
    console.error('Error dari auth logout = ', data.message)
  } catch (error) {
    console.error('Error dari auth logout', error)
  }
}
