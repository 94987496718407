import { overridedPrevHistories } from '../core/page'
import { selectedBranch } from '../core/subDivisions'

const SelectBranch = () => import('../pages/home/service/SelectBranch')
const Index = () => import('../pages/home/service/_id/Index.vue')
const SubServiceDetail = () => import('../pages/home/service/_id/SubServiceDetail.vue')
const Checkout = () => import('../pages/home/service/Checkout.vue')
const Summary = () => import('../pages/home/service/covid/Summary.vue')
const CovidPayment = () => import('../pages/home/service/covid/Payment.vue')
const CovidPaymentSuccess = () => import('../pages/home/service/covid/PaymentSuccess.vue')
const QRCode = () => import('../pages/home/service/covid/QRCode.vue')
const ScanVoucher = () => import('../pages/home/service/covid/Patient/ScanVoucher.vue')
const AddPatient = () => import('../pages/home/service/covid/Patient/AddPatient.vue')
const UseVoucher = () => import('../pages/home/service/covid/Patient/UseVoucher.vue')
const UseVoucherDetail = () => import('../pages/home/service/covid/Patient/UseVoucherDetail.vue')

export default [
  {
    path: '/pilih-cabang',
    name: 'SelectBranch',
    component: SelectBranch
  },
  {
    path: '/layanan/:id_layanan',
    name: 'SubService',
    component: Index,
    beforeEnter: (to, from, next) => {
      if (!selectedBranch.value) {
        return next({
          name: 'SelectBranch',
          query: {
            id_layanan: to.params.id_layanan
          }
        })
      }

      next()
    }
  },
  {
    path: '/layanan/:id_layanan/sub/:id_dt',
    name: 'SubServiceDetail',
    component: SubServiceDetail,
    beforeEnter: (to, from, next) => {
      if (!selectedBranch.value) {
        return next({
          name: 'SelectBranch',
          query: {
            id_layanan: to.params.id_layanan,
            id_dt: to.params.id_dt
          }
        })
      }

      next()
    }
  },
  {
    path: '/layanan/checkout',
    name: 'Checkout',
    component: Checkout,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'scan-voucher',
        name: 'PatientScanVoucher',
        component: ScanVoucher
      }
    ]
  },
  {
    path: '/layanan/checkout/tambah-pasien',
    name: 'AddPatient',
    component: AddPatient,
    meta: { requiresAuth: true }
  },
  {
    path: '/layanan/checkout/pakai-promo',
    name: 'UseVoucher',
    component: UseVoucher,
    meta: { requiresAuth: true }
  },
  {
    path: '/layanan/checkout/pakai-promo/detail',
    name: 'UseVoucherDetail',
    component: UseVoucherDetail,
    meta: { requiresAuth: true }
  },
  {
    path: '/layanan/covid/summary/:id_order',
    name: 'Summary',
    component: Summary,
    meta: { requiresAuth: true },
    beforeEnter (to, from, next) {
      /**
       * Ini biar kalau diklik back, dia redirect ke profile order
       */
      overridedPrevHistories.push({
        name: 'ProfilOrder'
      })

      next()
    }
  },
  {
    path: '/layanan/covid/payment/:id_order',
    name: 'CovidPayment',
    component: CovidPayment
  },
  {
    path: '/layanan/covid/payment/success',
    name: 'PaymentSuccess',
    component: CovidPaymentSuccess
  },
  {
    path: '/layanan/covid/qrcode',
    name: 'QRCode',
    component: QRCode
  }
]
