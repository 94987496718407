// tes lazy load
const Login = () => import('../pages/auth/Login')
const ForgotPassword = () => import('../pages/auth/ForgotPassword')
const SettingPassword = () => import('../pages/auth/SettingPassword')
const SettingPasswordDone = () => import('../pages/auth/SettingPasswordDone')
const Register = () => import('../pages/auth/Register')
const OTP = () => import('../pages/auth/Otp')
const OtpPhone = () => import('../pages/auth/OtpPhone')
const RegisterSuccess = () => import('../pages/auth/RegisterSuccess')
const ConfirmationSent = () => import('../pages/auth/ConfirmationSent')
const ConfirmationSuccess = () => import('../pages/auth/ConfirmationSuccess')
const PersonalData = () => import('../pages/auth/PersonalData')

export default [
  {
    path: '/login',
    name: 'Login',
    meta: {
      usingSecondNavbar: true
    },
    component: Login
  },
  {
    path: '/login/forgot-password',
    name: 'ForgotPassword',
    meta: {
      usingSecondNavbar: true
    },
    component: ForgotPassword
  },
  {
    path: '/login/forgot-password/set-password/:email',
    name: 'SettingPasswordOtp',
    meta: {
      usingSecondNavbar: true
    },
    component: SettingPassword
  },
  {
    path: '/login/forgot-password/set-password-done',
    name: 'SettingPasswordDone',
    component: SettingPasswordDone,
    meta: { requiresAuth: true, usingSecondNavbar: true }
  },
  {
    path: '/login/otp/:phone',
    name: 'OtpPhone',
    meta: {
      usingSecondNavbar: true
    },
    component: OtpPhone
  },
  {
    path: '/register',
    name: 'Register',
    meta: {
      usingSecondNavbar: true
    },
    component: Register
  },
  {
    path: '/register/otp/:email',
    name: 'OTP',
    meta: {
      usingSecondNavbar: true
    },
    component: OTP
  },
  {
    path: '/register/register-success',
    name: 'RegisterSuccess',
    component: RegisterSuccess,
    meta: { requiresAuth: true, usingSecondNavbar: true }
  },
  {
    path: '/register/confirmation-sent',
    name: 'ConfirmationSent',
    meta: {
      usingSecondNavbar: true
    },
    component: ConfirmationSent
  },
  {
    path: '/register/confirmation-success',
    name: 'ConfirmationSuccess',
    meta: {
      usingSecondNavbar: true
    },
    component: ConfirmationSuccess
  },
  {
    path: '/register/personal-data',
    name: 'PersonalData',
    meta: {
      usingSecondNavbar: true
    },
    component: PersonalData
  }
]
