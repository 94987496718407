import { ref, watch } from 'vue'

export const title = ref('Parahita')

export const withMobileMainBottomBar = ref(false)

/**
 * Stack untuk history browser yang dimodifikasi
 *
 * Jadi jika array (native) ini ada isinya, berarti di-pop() sebagai
 * pengganti dari real previous history
 */
export const overridedPrevHistories = []

/**
 * ini untuk nentukan animasinya, ke kiri apa ke kanan
 */
export const movingBack = ref(false)

watch(title, newTitle => {
  document.title = newTitle ? `${newTitle} | Hai Parahita` : 'Hai Parahita'
})
