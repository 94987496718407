<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <div class="container">
          <router-link to="/" class="navbar-brand">
            <img alt="logo" class="navbar-logo" src="@/assets/image.png" />
          </router-link>
          <div class="collapse navbar-collapse">
            <ul class="navbar-nav mb-2 mb-lg-0">
              <li class="nav-item">
                <router-link class="nav-link active" to="/">Beranda</router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Kontak' }">
                  Tentang Kami
                </router-link>
              </li>
              <li class="nav-item">
                <router-link
                  class="nav-link"
                  :to="{ name: 'Kontak' }">
                  Kontak
                </router-link>
              </li>
            </ul>
            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
              <li class="nav-item ms-auto" v-if="!isLoggedIn">
                <router-link to="/register">
                  <button class="btn btn-register">Daftar</button>
                </router-link>
                <!-- <button v-else class="btn btn-register" @click="logout">Keluar</button> -->
              </li>
              <li class="nav-item" v-if="!isLoggedIn">
                <router-link to="/login">
                  <button class="btn btn-login">Masuk</button>
                </router-link>
                <!-- <button v-else class="btn btn-register" @click="logout">Keluar</button> -->
              </li>
              <li v-else class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <img
                    v-if="user.foto"
                    class="avatar"
                    :src="user.foto"
                    :alt="user.pas_nama">
                  <img
                    v-else
                    class="avatar"
                    src="@/assets/user-placeholder.png"
                    :alt="user.pas_nama">
                  {{ user.pas_nama }}
                </a>

                <ul v-if="user.status_text === 'Belum Registrasi'" class="dropdown-menu custom-dropdown border-0" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <a class="dropdown-item fm-nunito text-body-light fw-600 fs-15" href="#" @click="logout">Logout</a>
                  </li>
                </ul>

                <ul v-else class="dropdown-menu custom-dropdown border-0" aria-labelledby="navbarDropdownMenuLink">
                  <li>
                    <router-link
                      class="text-decoration-none dropdown-item fm-nunito text-body-light fw-600 fs-15"
                      :to="{ name: 'Notifications' }">
                      Notifikasi <span class="badge bg-secondary">{{ notificationCount }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="text-decoration-none dropdown-item fm-nunito text-body-light fw-600 fs-15"
                      :to="{ name: 'Keranjang' }">
                      Keranjang <span class="badge bg-secondary">{{ cart.arr_paket.length }}</span>
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="text-decoration-none dropdown-item fm-nunito text-body-light fw-600 fs-15" to="/profile/order">
                      Pesanan
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="text-decoration-none dropdown-item fm-nunito text-body-light fw-600 fs-15" :to="{ name: 'Hasil' }">
                      Hasil Lab
                    </router-link>
                  </li>
                  <li>
                    <router-link
                      class="text-decoration-none dropdown-item fm-nunito text-body-light fw-600 fs-15" :to="{ name: 'Profil' }">
                      Profil
                    </router-link>
                  </li>
                  <li>
                    <a class="dropdown-item fm-nunito text-body-light fw-600 fs-15" href="#" @click="logout">Logout</a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
    </nav>
</template>

<script>
import { onMounted } from 'vue'
import { isLoggedIn, logout } from '@/core/auth'
import { getNotifications, notificationCount } from '@/core/notifications'
import { cart } from '@/core/cart'

export default {
  setup () {
    const user = JSON.parse(localStorage.getItem('user'))

    onMounted(() => {
      // Cek dulu biar ndak error user id
      if (user) {
        getNotifications()
      }
    })

    return {
      user,
      isLoggedIn,
      logout,
      notificationCount,
      cart
    }
  }
}
</script>

<style scoped>
nav.navbar {
  box-shadow: inset 0px -1px 0px rgba(242, 242, 242, 0.8);
}
.navbar-brand img {
  width: 180px;
  height: 46px;
}

.nav-item {
  padding-left: 1.6rem !important;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link:hover {
  color: #F79643 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #718096 !important;
}

.btn-register {
  background: white!important;
  width: 100px;
  height: 40px;
  border: 1px solid #E2E8F0;
  border-radius: 100px;
  color: #007A98 !important;
}

.btn-login {
  background: #007a98 !important;
  width: 100px;
  height: 40px;
  border-radius: 100px;
  color: #ffffff !important;
}

.custom-dropdown {
  border-radius: 8px;
}

.dropdown-item {
  color: #6E7191;
}

.dropdown-item:hover {
  color: #007A98;
}

.dropdown-item:active {
  color: #eee;
}

img.avatar {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}

.dropdown-menu.custom-dropdown {
  left: initial;
  right: 0;
}
</style>
