import { reactive, ref, watch } from 'vue'
import { user, token } from './auth'
import axios from '../plugins/axios'
import { analytics } from './firebase-messaging'
import { showNotif } from '@/core/utility'

export const orders = ref([])
export const offlineOrders = ref([])
export const detail = ref([])
export const offlineDetail = ref([])
export const contact = ref([])
export const referalCode = ref('')
export const referalList = ref([])
export const loading = reactive({
  order: false,
  detail: false,
  updatePersonalData: false,
  listKkp: false,
  downloadHasil: false,
  referalCode: false,
  referalList: false
})
export const listKkp = ref(JSON.parse(localStorage.getItem('listKkp')) || [])

/**
 * Ini untuk ngelog udah berapa kali si user
 * order pakai satu browser
 *
 * Fungsinya untuk decision nampilkan request notif permission
 */
export const ORDER_COUNT = ref(parseInt(
  localStorage.getItem('ORDER_COUNT') ?? 0
))

watch(ORDER_COUNT, () => {
  // log kalau udah pernah order, buat nampilin request
  // notif permission
  localStorage.setItem('ORDER_COUNT', ORDER_COUNT.value)
})

// Get kontaknya langsung running aja
const getContact = async () => {
  console.log('get contact running')
  try {
    const { data } = await axios.get('app/call_center')
    if (data.success) {
      contact.value = data.data[0]
    }
  } catch (error) {
    console.error('Error dari profile get contact = ', error)
    throw error
  }
}

getContact()

export const getOrders = async (payload) => {
  try {
    loading.order = true

    const { data } = await axios.get('app/data_daftar', {
      params: payload
    })

    orders.value = data.data
  } catch (error) {
    console.error('Error dari profile getOrders', error)
    // Reset jadi kosong kalau error
    orders.value = []
    throw error
  } finally {
    loading.order = false
  }
}

export const getOfflineOrders = async (payload) => {
  try {
    loading.order = true

    const { data } = await axios.get('app/data_daftar_offline', {
      params: payload
    })

    offlineOrders.value = data.data
  } catch (error) {
    console.error('Error dari profile getOfflineOrders', error)
    // Reset jadi kosong kalau error
    offlineOrders.value = []
    throw error
  } finally {
    loading.order = false
  }
}

export const deleteOrder = async (kode) => {
  try {
    const { data } = await axios.post('app/delete_daftar', {
      kode: kode
    })
    if (data.success === true) {
      window.location.reload()

      return {
        status: false,
        message: 'Gagal'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error(error)
  }
}

export const getDetail = async (payload) => {
  try {
    loading.detail = true

    const { data } = await axios.get('app/data_daftar_single', {
      params: payload
    })

    detail.value = {
      patients: data.data,
      services: data.data_detail
    }
  } catch (error) {
    console.error('Error dari profile getDetail', error)
    // Reset jadi kosong kalau error
    detail.value = []
    throw error
  } finally {
    loading.detail = false
  }
}

export const getOfflineDetail = async (payload) => {
  try {
    loading.detail = true

    const { data } = await axios.get('app/data_daftar_offline_detail', {
      params: payload
    })

    offlineDetail.value = data.data
  } catch (error) {
    console.error('Error dari profile getOfflineDetail', error)
    // Reset jadi kosong kalau error
    offlineDetail.value = []
    throw error
  } finally {
    loading.detail = false
  }
}

export const downloadHasil = async kode => {
  try {
    loading.downloadHasil = true

    const { data } = await axios.post('app/hasil_download', {
      kode
    })

    return data.data
  } catch (error) {
    console.error('Error dari profile downloadHasil', error)
    // Reset jadi kosong kalau error
    detail.value = []
    throw error
  } finally {
    loading.downloadHasil = false
  }
}

export const getListKkp = async ({ kode }) => {
  const KEY = `listKkp.${kode}`

  try {
    loading.listKkp = true
    listKkp.value = JSON.parse(localStorage.getItem(KEY)) || []

    const { data } = await axios.get('app/kkp_data', {
      params: {
        kode
      }
    })

    analytics.logEvent('lihat_kkp', { kode })

    listKkp.value = data.data.kkp
    localStorage.setItem(KEY, JSON.stringify(data.data.kkp))
  } catch (error) {
    console.error('Error dari profile getListKkp', error)
    // Reset jadi kosong kalau error
    detail.value = []
    throw error
  } finally {
    loading.listKkp = false
  }
}

export const getBiodata = async () => {
  const { data } = await axios.get('app/biodata', {
    params: {
      id: user.value?.id
    }
  })

  user.value = data.data[0]
  localStorage.setItem('user', JSON.stringify(user.value))
}

export const updatePersonalData = async (payload, { foto, ktp }) => {
  const {
    pas_id,
    pas_nama,
    pas_hp,
    pas_wa,
    pas_noktp,
    pas_tgllahir,
    pas_jenkelamin,
    email_user,
    pas_alamat,
    kode_provinsi,
    kode_kota,
    kode_kecamatan,
    kode_kelurahan
  } = payload

  try {
    loading.updatePersonalData = true

    const payloadFoto = new FormData()
    const payloadKtp = new FormData()

    const requests = [
      axios.post('app/update_biodata_v2', {
        pas_id,
        nama: pas_nama,
        noktp: pas_noktp,
        tgllahir: pas_tgllahir,
        jenkelamin: pas_jenkelamin,
        alamat: pas_alamat,
        email: email_user,
        pas_hp: pas_hp.replace('+62', '0').replace(/\s/g, ''),
        pas_wa: pas_wa.replace('+62', '0').replace(/\s/g, ''),
        kode_provinsi,
        kode_kota,
        kode_kecamatan,
        kode_kelurahan
      })
    ]

    if (foto) {
      payloadFoto.append('token_api', token.value) // blm ada token apinya ternyata
      payloadFoto.append('foto', foto)
      payloadFoto.append('pas_id', user.value.pas_id)
      requests.push(axios.post('app/upload_foto', payloadFoto))
    }

    if (ktp) {
      payloadKtp.append('token_api', token.value) // blm ada token apinya ternyata
      payloadKtp.append('ktp', ktp)
      payloadKtp.append('pas_id', user.value.pas_id)
      requests.push(axios.post('app/upload_ktp', payloadKtp))
    }

    await Promise.all(requests)

    analytics.logEvent('edit_profile')

    getBiodata()
    return true
  } catch (error) {
    console.error('Error dari profile update personal data', error)

    let msg = 'Error kirim data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })

    throw error
  } finally {
    loading.updatePersonalData = false
  }
}

export const getReferalCode = async () => {
  try {
    loading.referalCode = true

    const { data } = await axios.get('member/kode_referral', {
      headers: {
        id_user: user.value?.id,
        token: token.value
      }
    })

    referalCode.value = data.data.kode_referral
  } catch (error) {
    console.error('Error dari profile getReferalCode', error)
    let msg = 'Error get referal code'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    // Trigger toast
    showNotif({
      status: 'error',
      message: msg
    })
    // Reset jadi kosong kalau error
    referalCode.value = ''
    throw error
  } finally {
    loading.referalCode = false
  }
}

export const getReferalList = async () => {
  try {
    loading.referalList = true

    const { data } = await axios.get('member/list_referral', {
      headers: {
        id_user: user.value?.id,
        token: token.value
      }
    })

    referalList.value = data.data
  } catch (error) {
    console.error('Error dari profile getreferalList', error)
    // Reset jadi kosong kalau error
    referalList.value = []
    throw error
  } finally {
    loading.referalList = false
  }
}
