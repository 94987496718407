const Order = () => import('../../pages/profile/orders/Order.vue')
const OrderDetail = () => import('../../pages/profile/orders/OrderDetail.vue')
const OfflineOrderDetail = () => import('../../pages/profile/orders/OfflineOrderDetail.vue')
const History = () => import('../../pages/profile/History.vue')
const ListKkp = () => import('../../pages/profile/orders/ListKkp.vue')

export default [
  {
    path: 'order',
    name: 'ProfilOrder',
    component: Order,
    meta: {
      requiresAuth: true,
      mobileMainBottomBar: true,
      noProfileCard: true,
      overrideAppBarTitle: 'Pesanan'
    }
  },
  {
    path: 'order/:id_order/detail',
    name: 'ProfilOrderDetail',
    component: OrderDetail,
    meta: {
      requiresAuth: true,
      noProfileCard: true
    }
  },
  {
    path: 'offline-order/:id_order/detail',
    name: 'ProfilOfflineOrderDetail',
    component: OfflineOrderDetail,
    meta: {
      requiresAuth: true,
      noProfileCard: true
    }
  },
  {
    path: 'order/history',
    name: 'OrderHistory',
    component: History,
    meta: { requiresAuth: true }
  },
  {
    path: 'order/:id_order/kkp',
    name: 'Kkp',
    component: ListKkp,
    meta: {
      requiresAuth: true,
      noProfileCard: true,
      overrideAppBarTitle: 'Daftar KKP'
    }
  }
]
