<template>
  <div
    id="toastNotif"
    :class="'toast text-white bg-' + notif.status"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
  >
    <div class="toast-header">
      <strong class="me-auto text-capitalize">{{ notif.status }}</strong>
      <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body" v-html="notif.message" />
  </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue'
import { Toast } from 'bootstrap'
import { notif, resetNotif } from '@/core/utility'

export default {
  setup () {
    const toast = ref(null)
    onMounted(() => {
      const toastElement = document.getElementById('toastNotif')
      toast.value = new Toast(toastElement, {
        animation: true,
        autohide: true,
        delay: 3000
      })

      // Set callback saat toast ditutup
      toastElement.addEventListener('hidden.bs.toast', function () {
        resetNotif()
      })
    })

    watch(notif, (newNotif) => {
      // Tampilkan toast jika true
      // Biar tidak infinite loop
      if (newNotif.show) {
        toast.value.show()
      }
    })

    return {
      notif
    }
  }
}
</script>

<style scoped>
.bg-success {
  background-color: #8CC63F !important;
}

.bg-error {
  background-color: #dc3545 !important;
}

.toast:not(.showing):not(.show) {
  display: none;
}
</style>
