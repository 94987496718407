import { ref, reactive, watch } from 'vue'
import axios from '../plugins/axios'
import { selectedBranch } from './subDivisions'
import { showNotif } from '@/core/utility'
import { analytics } from './firebase-messaging'
import { activeService } from '@/core/service'
import { kode_promo, user } from './auth'

export const diskon = ref('')
export const loading = reactive({
  voucher: false,
  cart: false
})
export const diskonPenggunaBaru = reactive({
  nominal: 0,
  message: ''
})
export const isErrorVoucher = reactive({
  voucherDouble: { status: false, message: '' },
  voucherPenggunaBaru: { status: false, message: '' }
})
export const voucherTemp = ref('')
export const cart = ref({
  ap_nombayar: 0,
  ap_catatan: '',
  asal_data: 'WEB',
  status_bayar: 0, // 0 = online, 1 = offline
  cabang_periksa: 0,
  arr_paket: [],
  status_periksa: 1,
  pas_id_pendaftar: 0,
  id_pendaftar: 0,
  pas_id_periksa: 0,
  kode_voucher: '',
  nominal_voucher: 0,
  anamnesa_keluahan: '',
  anamnesa_jam_makan_terakhir: '',
  anamnesa_obat_yg_dikonsumsi: '',
  anamnesa_riwayat_penyakit: '',
  anamnesa_riwayat_alergi: '',
  anamnesa_penyakit_keluarga: '',
  anamnesa_kebiasaan_merokok: 'TIDAK', // YA, TIDAK
  print_out: 0, // 0 = tidak, 1 = ya
  dikirim_status: 0, // 0 = ambil sendiri, 1 = dikirim
  dikirim_alamat: '',
  tgl_periksa: ''
})

// Reset cabang saat cart dikosongkan
watch(
  () => cart.value.arr_paket,
  (newValue) => {
    if (newValue.length === 0) {
      // disable sementara karena menghambat proses testing wkwk
      selectedBranch.value = null
      console.log('Branch reset success')
    }
  },
  { deep: true }
)

export const addToCart = async (payload) => {
  try {
    if (!user.value) {
      return {
        status: false,
        message: 'login'
      }
    }

    const { data } = await axios.post('app/tambah_keranjang', {
      kode: payload.kode,
      id_dt: payload.id_dt,
      nama: payload.nama,
      harga: parseInt(payload.harga),
      id_pendaftar: user.value.id,
      cabang: parseInt(selectedBranch.value.id)
    })

    if (data.success === true) {
      // Update isi cart
      cart.value.ap_nombayar += parseInt(payload.harga)
      cart.value.cabang_periksa = parseInt(selectedBranch.value.id)

      return {
        status: true,
        message: data.message
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error add to cart = ', error)

    return {
      status: false,
      message: error.response.data?.message ?? 'Error tambah keranjang'
    }
  } finally {
    const trackingObject = {
      items: [{
        item_id: payload.kode,
        item_name: payload.nama,
        price: parseFloat(payload.harga),
        currency: 'IDR',
        item_category: activeService.value?.nama,
        item_list_name: `${activeService.value?.nama} cabang ${selectedBranch.value?.nama}`,
        item_list_id: `${activeService.value?.id_group}_cabang_${selectedBranch.value?.id}`,
        quantity: 1
      }],
      value: parseFloat(payload.harga),
      currency: 'IDR'
    }
    analytics.logEvent('add_to_cart', trackingObject)
  }
}

export const getCart = async () => {
  loading.cart = true
  if (!user.value) {
    loading.cart = false
    return {
      status: false,
      message: 'Harus login terlebih dahulu'
    }
  }
  try {
    const { data } = await axios.get('app/list_keranjang_cabang', {
      params: {
        id_pendaftar: user.value.id,
        id_cabang: selectedBranch.value.id
      }
    })

    if (data.success === true) {
      if (data.data.length > 0) {
        // Update selected branch
        selectedBranch.value.id = data.data[0].cabang
        // Update isi cart
        cart.value.arr_paket = data.data
        cart.value.ap_nombayar = data.data.reduce((acc, item) => acc + parseInt(item.harga), 0)
        cart.value.cabang_periksa = parseInt(selectedBranch.value.id)
      }
      return {
        status: true,
        message: data.message
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error(error)

    return {
      status: false,
      message: error.response?.data?.message ?? 'Error tambah keranjang'
    }
  } finally {
    loading.cart = false
  }
}

export const clearCart = async (withNotif = true) => {
  try {
    const { data } = await axios.post('app/hapus_semua_keranjang', {
      id_pendaftar: user.value.id
    })

    if (data.success === true) {
      // reset diskon
      diskon.value = ''

      // reset reactive cart
      cart.value = {
        ap_nombayar: 0,
        ap_catatan: '',
        asal_data: 'WEB',
        status_bayar: 0, // 0 = online, 1 = offline
        cabang_periksa: 0,
        arr_paket: [],
        status_periksa: 1,
        pas_id_pendaftar: 0,
        id_pendaftar: 0,
        pas_id_periksa: 0,
        kode_voucher: '',
        nominal_voucher: 0,
        anamnesa_keluahan: '',
        anamnesa_jam_makan_terakhir: '',
        anamnesa_obat_yg_dikonsumsi: '',
        anamnesa_riwayat_penyakit: '',
        anamnesa_riwayat_alergi: '',
        anamnesa_penyakit_keluarga: '',
        anamnesa_kebiasaan_merokok: 'TIDAK', // YA, TIDAK
        print_out: 0, // 0 = tidak, 1 = ya
        dikirim_status: 0, // 0 = ambil sendiri, 1 = dikirim
        dikirim_alamat: '',
        tgl_periksa: ''
      }

      if (withNotif) {
        showNotif({
          status: 'success',
          message: data.message
        })
      }
    } else {
      showNotif({
        status: 'error',
        message: data.message
      })
    }
  } catch (error) {
    console.error(error)

    showNotif({
      status: 'error',
      message: error.response.data?.message ?? 'Error hapus semua keranjang'
    })
  }
}

export const checkVoucher = async (payload) => {
  try {
    loading.voucher = true

    analytics.logEvent('check_voucher', payload)

    const { data } = await axios.post('app/voucher', payload)

    return data
  } catch (error) {
    console.error('error service check voucher = ', error)
    throw error
  } finally {
    loading.voucher = false
  }
}

export const checkVoucherDouble = async (payload) => {
  try {
    loading.voucher = true

    analytics.logEvent('check_voucher', payload)

    const { data } = await axios.post('app/voucher_double', payload)

    return data
  } catch (error) {
    console.error('error service check voucher double = ', error)
    isErrorVoucher.voucherDouble.message = error.response.data.message
    throw error
  } finally {
    isErrorVoucher.voucherDouble.status = true
    loading.voucher = false
  }
}

export const checkVoucherPenggunaBaru = async (payload) => {
  try {
    loading.voucher = true

    analytics.logEvent('check_voucher_pengguna_baru', payload)

    const { data } = await axios.post('app/voucher_baru', payload)

    return data
  } catch (error) {
    console.error('error service check voucher pengguna baru = ', error)
    isErrorVoucher.voucherPenggunaBaru.message = error.response.data.message

    throw error
  } finally {
    isErrorVoucher.voucherPenggunaBaru.status = true
    loading.voucher = false
  }
}

export const clearVoucher = () => {
  cart.value.kode_voucher = ''
  cart.value.nominal_voucher = diskonPenggunaBaru.nominal
  diskon.value = ''
  kode_promo.value = ''
}

export const countVoucher = async () => {
  try {
    // Prepare var
    let functionCheckVoucher = checkVoucher

    // reset value voucher dulu
    cart.value.nominal_voucher = 0

    // pertama, cek apakah pengguna baru
    if (parseInt(user.value.pasien_baru)) {
      // Ganti function untuk hitung voucher nya
      functionCheckVoucher = checkVoucherDouble

      // hitung diskon pengguna baru
      const resultPenggunaBaru = await checkVoucherPenggunaBaru({
        id: user.value.id,
        nominal: cart.value.ap_nombayar,
        asal_data: 'WEB',
        arr_paket: cart.value.arr_paket
      })

      if (resultPenggunaBaru.jenis_diskon === 'rp') {
        diskonPenggunaBaru.message = 'Diskon pengguna baru'
        diskonPenggunaBaru.nominal = 'Rp. ' + resultPenggunaBaru.val
        cart.value.nominal_voucher = parseInt(resultPenggunaBaru.val)
      } else if (resultPenggunaBaru.jenis_diskon === '%') {
        diskonPenggunaBaru.message = 'Diskon 5% untuk pengguna baru'
        diskonPenggunaBaru.nominal = resultPenggunaBaru.diskon
        cart.value.nominal_voucher = parseInt(resultPenggunaBaru.diskon)
      }
    }

    // Kalau input kode voucher
    if (cart.value.kode_voucher) {
      const result = await functionCheckVoucher({
        kode_voucher: cart.value.kode_voucher,
        nominal: cart.value.ap_nombayar,
        asal_data: 'WEB',
        arr_paket: cart.value.arr_paket
      })

      if (result.jenis_diskon === 'rp') {
        diskon.value = 'Rp. ' + parseInt(result.val).toLocaleString('id-ID')
        cart.value.nominal_voucher += parseInt(result.val)
      } else if (result.jenis_diskon === '%') {
        diskon.value = result.diskon
        cart.value.nominal_voucher += parseInt(result.diskon)
      }
    } else {
      clearVoucher()
    }
    return 1
  } catch (error) {
    // Reset diskon dan total new
    clearVoucher()

    // check pengguna baru
    if (!parseInt(user.value.pasien_baru)) {
      showNotif({
        status: 'error',
        message: error.response.data.message ?? error.response.data.error
      })
    }

    return 0
  }
}

// Prepare function
export const removeFromCart = async (item) => {
  try {
    const { data } = await axios.post('app/hapus_keranjang', {
      id_pendaftar: user.value.id,
      id_keranjang: item.id_keranjang
    })

    if (data.success === true) {
      const itemIndex = cart.value.arr_paket.findIndex(_item => _item.id_keranjang === item.id_keranjang)
      if (itemIndex > -1) {
        cart.value.arr_paket.splice(itemIndex, 1) // Hapus dari cart
        cart.value.ap_nombayar -= item.harga // Kurangi harganya
        await countVoucher() // Hitung ulang voucher

        const trackingObject = {
          items: [{
            item_id: item.kode,
            item_name: item.nama,
            price: parseFloat(item.harga),
            currency: 'IDR',
            item_category: item.nama_group ?? item.nama_group_fpp,
            item_list_name: `${item.nama_group ?? item.nama_group_fpp} cabang ${selectedBranch.value?.nama}`,
            item_list_id: `${item.id_group ?? item.id_fpp}_cabang_${selectedBranch.value?.id}`,
            quantity: 1
          }],
          value: parseFloat(item.harga),
          currency: 'IDR'
        }

        analytics.logEvent('remove_from_cart', trackingObject)
      }
      // showNotif({
      //   status: 'success',
      //   message: data.message
      // })
      return {
        status: true,
        message: data.message
      }
    }
    // else {
    //   showNotif({
    //     status: 'error',
    //     message: data.message
    //   })

    //   return
    // }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error from remove paket = ', error)

    showNotif({
      status: 'error',
      message: error.response.data?.message ?? 'Error hapus semua keranjang'
    })
  }
}
