import { mcu } from '../core/mcu'

const Index = () => import('../pages/home/mcu/Index.vue')
const Detail = () => import('../pages/home/mcu/Detail.vue')
const ListKkp = () => import('../pages/home/mcu/ListKkp.vue')
const DetailKkp = () => import('../pages/home/mcu/DetailKkp.vue')

export default [
  {
    path: '/mcu',
    name: 'MCU',
    component: Index,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/mcu/detail/:kode?',
    name: 'MCUDetail',
    component: Detail,
    meta: {
      requiresAuth: true
    },
    beforeEnter: (to, from, next) => {
      if (!mcu.value) {
        return next({
          name: 'MCU'
        })
      }

      next()
    }
  },
  {
    path: '/mcu/:kode/kkp',
    name: 'MCUKkp',
    component: ListKkp,
    meta: {
      requiresAuth: true,
      overrideAppBarTitle: 'Daftar KKP'
    },
    beforeEnter: (to, from, next) => {
      if (!mcu.value) {
        return next({
          name: 'MCU'
        })
      }

      next()
    }
  },
  {
    path: '/mcu/:kode/kkp/:kkp',
    name: 'MCUDetailKkp',
    component: DetailKkp,
    meta: {
      requiresAuth: true,
      overrideAppBarTitle: 'Detail KKP'
    },
    beforeEnter: (to, from, next) => {
      if (!mcu.value) {
        return next({
          name: 'MCU'
        })
      }

      next()
    }
  }
]
