import axios from '../plugins/axios'
import { ref, reactive } from 'vue'
import { showNotif } from '@/core/utility'
import { user } from '@/core/auth'

export const mcu = ref(null)
export const detailMcu = ref(null)
export const kodeRekanan = ref('')
export const androidMonitoring = reactive({
  header: {},
  detail: []
})
export const loading = reactive({
  mcu: false,
  monitoring: false,
  hasil: false,
  androidMonitoring: false
})

export const confirmMcu = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/konfirmasi_mcu', payload)

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses'
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari mcu get mcu', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const getDetailMcu = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/detail_mcu', payload)

    if (data.success === true) {
      detailMcu.value = data.data[0]
      return {
        status: true,
        message: 'Sukses'
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari mcu get detail mcu', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const getMcu = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/rekanan_cek', payload)

    if (data.success === true) {
      // data.data.deklarasi_sehat = 0
      // data.data.anamnesa = 0
      // data.data.sds_srq = 0
      // data.data.barcode = ''
      // data.data.biodata.foto = ''
      // data.data.hasil = 1
      // data.data.monitoring = 1

      // simulasi hide dari api
      // data.data.tab_menu.splice(1, 1)
      // data.data.tab_menu[0].status_isi_data = 1
      // data.data.tab_menu[1].status_isi_data = 1
      // data.data.tab_menu[2].status_isi_data = 0
      // data.data.tab_menu[3] = {
      //   icon: "https://its.labparahita.com/assets/ico/qr.png",
      //   judul_tab_menu: "QR CODE",
      //   kode_tab: "hasil",
      //   status_isi_data: 0,
      //   urutan: "6"
      // }

      mcu.value = data.data
      mcu.value.kode_rekanan = payload.kode_rekanan // Inject kode rekanan
      return {
        status: true,
        message: 'Sukses'
      }
    }
    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('Error dari mcu get mcu', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const getPertanyaan = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/detail_pertanyaan', payload)

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses',
        data: data.data
      }
    }
    return {
      status: false,
      message: data.message,
      data: []
    }
  } catch (error) {
    console.error('Error dari mcu get pertanyaan', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const submitPertanyaan = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/pertanyaan', payload)

    if (data.success === true) {
      const result = await getMcu({
        kode_rekanan: mcu.value.kode_rekanan,
        pas_noktp: user.value.pas_noktp
      })
      if (!result.status) {
        return {
          status: false,
          message: result.message,
          data: []
        }
      } else {
        return {
          status: true,
          message: 'Sukses',
          data: data.data
        }
      }
    }
    return {
      status: false,
      message: data.message,
      data: []
    }
  } catch (error) {
    console.error('Error dari mcu submit pertanyaan', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const submitFoto = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.post('mcu/upload_foto', payload)

    if (data.success === true) {
      const result = await getMcu({
        kode_rekanan: mcu.value.kode_rekanan,
        pas_noktp: user.value.pas_noktp
      })
      if (!result.status) {
        return {
          status: false,
          message: result.message,
          data: []
        }
      } else {
        return {
          status: true,
          message: 'Sukses',
          data: data.data
        }
      }
    }
    return {
      status: false,
      message: data.message,
      data: []
    }
  } catch (error) {
    console.error('Error dari mcu submit foto', error)
    throw error
  } finally {
    loading.mcu = false
  }
}

export const getListKkp = async (payload) => {
  try {
    loading.mcu = true
    const { data } = await axios.get('mcu/kkp_data', {
      params: payload
    })

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses',
        data: data.data
      }
    }
    return {
      status: false,
      message: data.message,
      data: []
    }
  } catch (error) {
    console.error('Error dari mcu get list kkp', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.mcu = false
  }
}

export const getMonitoring = async (payload) => {
  try {
    loading.monitoring = true
    const { data } = await axios.post('mcu/monitoring_mcu', payload)

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses',
        header: data.header,
        detail: data.detail
      }
    }
    return {
      status: false,
      message: data.message,
      header: [],
      detail: []
    }
  } catch (error) {
    console.error('Error dari mcu get monitoring', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.monitoring = false
  }
}

export const getHasil = async (payload) => {
  try {
    loading.hasil = true
    const { data } = await axios.post('mcu/data_hasil_mcu', payload)

    if (data.success === true) {
      return {
        status: true,
        message: 'Sukses',
        biodata: data.biodata,
        hasil: data.hasil
      }
    }
    return {
      status: false,
      message: data.message,
      biodata: {},
      hasil: []
    }
  } catch (error) {
    console.error('Error dari mcu get getHasil', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.hasil = false
  }
}

export const getAndroidMonitoring = async (payload) => {
  try {
    loading.androidMonitoring = true
    const { data } = await axios.post('mcu/monitoring_mcu', payload)

    if (data.success) {
      androidMonitoring.header = data.header
      androidMonitoring.detail = data.detail
      return {
        status: true,
        message: 'Berhasil ambil data monitoring'
      }
    }

    return {
      status: false,
      message: data.message
    }
  } catch (error) {
    console.error('error get android mcu monitoring = ', error)
    let msg = 'Error ambil data'
    if (error.response?.data?.message) {
      msg = error.response.data.message
    }

    showNotif({
      status: 'error',
      message: msg
    })
    throw error
  } finally {
    loading.androidMonitoring = false
  }
}
