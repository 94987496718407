const Index = () => import('@/pages/home/wallet/Index')
const Topup = () => import('@/pages/home/wallet/Topup')
const Payment = () => import('@/pages/home/wallet/Payment')

export default [
  {
    path: '/wallet',
    name: 'Wallet',
    component: Index
  },
  {
    path: '/wallet/top-up',
    name: 'Topup',
    component: Topup
  },
  {
    path: '/wallet/top-up/payment',
    name: 'Payment',
    component: Payment
  }
]
