import { defineRule } from 'vee-validate'

defineRule('required', value => {
  if (!value || !value.length) {
    return 'Kolom tidak boleh kosong'
  }

  return true
})

defineRule('email', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return 'Kolom tidak boleh kosong'
  }

  // Check if email
  if (!/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(value)) {
    return 'Email tidak valid'
  }

  return true
})

defineRule('emailOrPhone', value => {
  // tidak boleh kosong
  if (!value || !value.length) {
    return 'Kolom tidak boleh kosong'
  }

  if (/^\d+$/.test(value) || /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}/.test(value)) {
    return true
  }

  return 'Format tidak sesuai'
})

defineRule('confirmed', (value, [target], ctx) => {
  if (value === ctx.form[target]) {
    return true
  }

  return 'Kata sandi harus sama'
})

defineRule('digits', (value, [digit]) => {
  if (!value || !value.length) {
    return 'Kolom tidak boleh kosong'
  }

  if (value.length !== parseInt(digit)) {
    return `Panjang karakter harus ${digit} digits`
  }

  return true
})
