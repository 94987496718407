import setting from './setting'
import notification from './notification'
import order from './order'

const _Layout = () => import('../../pages/profile/_Layout')
const Index = () => import('../../pages/profile/Index.vue')
const KontakParahita = () => import('../../pages/profile/KontakParahita.vue')
const Referal = () => import('../../pages/profile/Referal.vue')

export default [
  {
    path: '/profile',
    component: _Layout,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'Profil',
        component: Index
      },
      {
        path: '/kontak',
        name: 'ProfilKontak',
        component: KontakParahita
      }
    ]
      .concat(setting)
      .concat(order)
      .concat(notification)
  },
  {
    path: '/referal',
    name: 'Referal',
    component: Referal
  }
]
