<template>
     <div
      class="modal fade full-on-mobile"
      id="modal-main-service"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style="z-index: 9999">
        <div class="modal-dialog modal-lg">
          <div class="modal-content">
            <div class="modal-header d-flex justify-content-between py-4 border-0">
              <div class="bd-highlight">
                <p
                  class="modal-title text-active fm-poppins fst-normal fw-600 lh-36 mb-0"
                  id="exampleModalLabel">
                  Semua Layanan
                </p>
              </div>
              <div class="bd-highlight d-flex align-items-center justify-content-end w-50">
                  <div class="bd-highlight">
                    <div class="input-group d-none d-md-block">
                      <input type="text" class="form-control bg-grayscale text-placeholder fm-nunito fst-normal fw-600 fs-12 lh-16 p-3 border-0"
                      placeholder="Cari Layanan"
                      name="search"
                      v-model="textInput"
                      v-on:click="search">
                    </div>
                  </div>
                  <div class="bd-highlight px-4">
                    <button type="button" id="btn-close-modal" class="btn text-primary fm-poppins fst-normal fw-600 fs-12" data-bs-dismiss="modal" aria-label="Close">Tutup</button>
                  </div>
              </div>
            </div>
            <div
              class="modal-body py-0 py-md-2">
              <div class="service-container">
                <div
                  class="d-flex flex-wrap flex-md-nowrap py-0 px-0 py-sm-5 px-sm-4
                  align-items-start align-items-md-center
                  justify-content-start justify-content-md-around">
                  <router-link
                    v-for="(data, index) in data.data"
                    :key="index"
                    class="card card-service"
                    :class="{
                      'd-none': data.id_group == 21
                    }"
                    :to="generateUrl(parseInt(data.id_group))">
                    <div class="card-body d-flex flex-column align-items-center justify-content-center py-3 px-0 w-100">
                        <div class="bd-highlight">
                            <img class="mb-4" :src="data.icon" alt="" width="64" height="64">
                        </div>
                        <div class="bd-highlight">
                            <p class="text-center text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">{{ data.nama }}</p>
                        </div>
                    </div>
                  </router-link>

                  <!-- <router-link
                    :to="{
                      name: 'HomeServiceSelectBranch'
                    }"
                    class="card card-service"
                  >
                    <div class="card-body d-flex flex-column align-items-center justify-content-center py-3 px-0 w-100">
                        <div class="bd-highlight">
                            <img class="mb-4" src="https://its.labparahita.com/assets/app/iconrev/06-TestPemeriksaan.png" alt="" width="64" height="64">
                        </div>
                        <div class="bd-highlight">
                            <p class="text-center text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">Home Services</p>
                        </div>
                    </div>
                  </router-link>

                  <router-link
                    :to="{
                      name: 'Promo'
                    }"
                    class="card card-service"
                  >
                    <div class="card-body d-flex flex-column align-items-center justify-content-center py-3 px-0 w-100">
                        <div class="bd-highlight">
                            <img class="mb-4" src="https://its.labparahita.com/assets/app/iconrev/06-TestPemeriksaan.png" alt="" width="64" height="64">
                        </div>
                        <div class="bd-highlight">
                            <p class="text-center text-gray-3 fm-nunito fst-normal fw-600 fs-12 lh-16 mb-0">Promo</p>
                        </div>
                    </div>
                  </router-link> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import axios from '@/plugins/axios'

export default {
  data: () => ({
    textInput: '',
    data: []
  }),
  methods: {
    async getService () {
      const { data } = await axios.get('app/layanan')
      this.data = data
    },
    isCloseModal () {
      const modal = document.getElementById('btn-close-modal')
      modal.close()
    },
    generateUrl (id_group) {
      if (id_group === 7) { // FPP
        return {
          name: 'Fpp',
          replace: true
        }
      } else if (id_group === 10) { // MCU
        return {
          name: 'MCU'
        }
      } else {
        return {
          name: 'SubService',
          params: {
            id_layanan: id_group
          }
        }
      }
    }
  },
  mounted () {
    this.getService()
  },
  computed: {
    search () {
      if (this.textInput) {
        return this.services.filter((data) => {
          return this.textInput.toLowerCase().split(' ').every(v => data.title.toLowerCase().includes(v))
        })
      } else {
        return this.services
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.service-container {
  @media screen and (max-width: 576px) {
    height: fit-content;
    margin-top: 2.5rem;
    border: none;
  }

  .card-service {
    height: 142px;
    align-items: center;
    width: fit-content;
    background: #FFFFFF;
    // border none aja biar ndak ada bordernya
    border: none; //1px solid #F2F2F2
    box-sizing: border-box;
    border-radius: 10px !important;
    flex: none;
    display: block;

    @media screen and (max-width: 576px) {
      padding: 0;
      height: fit-content;
      flex: none;
      width: 33.33333%;
      text-decoration: none;

      .title-service {
        padding: 0;

        p {
          padding: 0;
        }
      }

      .icon-service {
        width: 3.5rem;
        aspect-ratio: 1 / 1;
        margin-bottom: .5rem;
        background-color: rgb(228, 228, 228);
      }
    }
  }
}

  .input-group .form-control {
    border-radius: 8px !important;
    width: 17rem;
    height: 2.5rem;
  }

  .modal-content {
    @media (min-width: 520px) {
      height: 90vh;
    }
  }

  .modal-title {
    font-size: 18px;

    @media (min-width: 520px) {
      font-size: 24px;
    }
  }
</style>
