<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-trasnparent w-100">
        <div class="container">
          <router-link to="/" class="navbar-brand">
            <img width="180" height="46" alt="logo" class="navbar-logo" src="@/assets/image.png" />
          </router-link>
        </div>
    </nav>
</template>

<style scoped>
.navbar {
  position: absolute;
}

</style>
